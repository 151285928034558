import * as React from "react";
import BlogMain from "../../components/Blog/BlogMain";
import ComingSoon from "../../components/ComingSoon";

export default function Blog() {
  return (
    <div>
      <BlogMain />
      <ComingSoon />
    </div>
  );
}
