import { AnimatePresence } from "framer-motion";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import About from "./pages/About";
import Blog from "./pages/Blog/Blog";
import Post from "./pages/Blog/Post";
import NotFound from "./pages/NotFound";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
mixpanel.init("ceabb498e481bc503a2e70db3546f45f", { debug: true });

function App() {
  const location = useLocation();

  useEffect(() => {
    mixpanel.track("session-started");
  }, []);

  return (
    <div className="page-bg">
      <Header />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog">
            <Route index element={<Blog />} />
            <Route path=":id" element={<Post />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
