import { useRef } from "react";
import "./Home.css";
import { motion, useTransform, useScroll } from "framer-motion";

export default function IntroSection({ container }) {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    container,
    layoutEffect: false,
    offset: ["0 1", "1 0"],
  });

  const x = useTransform(scrollYProgress, [0, 1], ["30%", "-95%"]);

  return (
    <section className="intro-section">
      <p className="intro-section-text">
        Different <span className="bold">Handlooms</span> from Different parts
        of India
      </p>
      <div className="intro-section-caurosel">
        <motion.div className="intro-section-card-wrapper" style={{ x: x }}>
          {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
        </motion.div>
      </div>
      <p className="intro-section-text-2">Empowering our Handloom Weavers!</p>
    </section>
  );
}

const Card = ({ card }) => {
  const getImage = (date) => {
    const modifiedDate = String(date).replaceAll(",", "-").toLowerCase();
    return require(`../../images/uncompressed/${modifiedDate}.webp`);
  };

  return (
    <div className="intro-section-card" key={card.id}>
      <div
        className="intro-section-card-img"
        style={{
          backgroundImage: `url(${getImage(card.date)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <span className="intro-section-card-text">{card.date}</span>
    </div>
  );
};

const cards = [
  {
    date: "20,Feb,23",
    tag: "started",
  },
  {
    date: "27,Mar,23",
  },
  {
    date: "24,April,23",
  },
  {
    date: "16,Oct,23",
  },
  {
    date: "4,Dec,23",
  },
  {
    date: "8,Jan,23",
  },
  {
    date: "5,Feb,24",
    tag: "Recent",
  },
];
