import * as React from "react";

export default function Faqs() {
  return (
    <div className="faqs-about">
      <p className="body-text">FAQs</p>
      {FAQs.map((faq, index) => {
        return (
          <div key={index}>
            <p>{faq.title}</p>
            <p>{faq.description}</p>
          </div>
        );
      })}
      <p>More FAQs will be added!</p>
    </div>
  );
}

// data

const FAQs = [
  {
    title: "How to find handloom stores ?",
    description: (
      <ul>
        <li>
          Khadi realted stores in google maps, mostly i saw every city has it.
        </li>
        <li>
          Handloom exhibitions in google maps Handlooms by state government.
          <div>ex : Cauvery handlooms , Karanataka Govt.</div>
        </li>
      </ul>
    ),
  },
];
