import { useRef } from "react";
import HeroSection from "../components/Home/HeroSection";
import IntroSection from "../components/Home/IntroSection";
import transition from "../hoc/transition";
import Facts from "../components/Home/Facts";
import Support from "../components/Home/Support";
import Benefits from "../components/Home/Benefits";
import History from "../components/Home/History";

function Home() {
  const ref = useRef(null);
  return (
    <div
      style={{
        height: "90vh",
        overflowY: "scroll",
      }}
      className="hide-scroll"
      ref={ref}
    >
      <HeroSection />
      <IntroSection container={ref} />
      <Facts />
      <Support />
      <History />
    </div>
  );
}

export default transition(Home);
