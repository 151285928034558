import { inView, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export default function Support() {
  const [activeAction, setActiveAction] = useState();
  return (
    <section className="support-wrap">
      <p className="body-text">Lets Support By</p>
      <div className="support-card-wrap">
        <div className="support-actions">
          <ul>
            {SupportActions.map((action, index) => {
              return (
                <SupportContent
                  onActive={setActiveAction}
                  key={index}
                  action={action}
                  i={index}
                />
              );
            })}
          </ul>
        </div>
        {activeAction?.src ? (
          <div className="support-img-wrapper">
            <img src={activeAction?.src} />
          </div>
        ) : null}
      </div>
    </section>
  );
}

const SupportContent = ({ action, onActive }) => {
  const ref = useRef();
  const isInView = useInView(ref, { margin: "-50% 0px -50% 0px" });

  useEffect(() => {
    if (isInView) {
      onActive(action);
    }
  }, [isInView]);
  return (
    <li className={`support-content ${isInView ? "active" : ""}`}>
      <p className="title" ref={ref}>
        {action.title}
      </p>
      <p>{action.description}</p>
    </li>
  );
};

const SupportActions = [
  {
    title: "Buy their products directly",
    src: "https://images.unsplash.com/photo-1619239635762-8132f6dba51c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description:
      "Many new generation weavers are using social platforms, websites, WhatsApp and phone call services to sell the products.",
  },
  {
    title: "Pre-book or pre-buy",
    src: "https://images.unsplash.com/photo-1627296345489-faf81a8f15ae?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description:
      "Since a lot of investment is required to produce handloom products at a large scale, weavers are always under the dilemma of whether their products will sell or not.When we pre-book their products, they feel more at ease to go big and bold with their collections.",
  },
  {
    title: "Volunteer for them",
    src: "https://images.unsplash.com/photo-1611574557351-00889b20a36b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description:
      "Reach out to the local artisans and offer to help them in whatever way possible. You can create a social media account for them to increase their reach. Or you can help them create a website.",
  },
  {
    title: "Contribute to fundraisers",
    src: "https://images.unsplash.com/photo-1619239635762-8132f6dba51c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description:
      "Several Indian weavers are in need of support. If you have the capacity to donate to fundraisers, do not hesitate. To help the weavers with their food ration and health or educational supplies, contribute to fundraisers on Milaap and other platforms.",
  },
  {
    title: "Be vocal about Indian fabrics",
    src: "https://images.unsplash.com/photo-1640239259272-6f26a9affb98",
    description:
      "Just the way you sing praises of International luxury brands like Dior and Chanel, share what you like about Indian fabrics whenever possible. Use your voice to help the Indian artisans.",
  },
  {
    title: "Appreciate, Follow & Share weavers on social media",
    src: "https://images.unsplash.com/photo-1611574557210-e18bc59fd02b",
    description:
      "Social media trends help increase a product's demand. So, the more you post about your favourite handloom brands, the more profits they will earn.",
  },
];
