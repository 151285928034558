import * as React from "react";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  return (
    <nav>
      <NavLink to={"/"} className="nav-link">
        Home
      </NavLink>
      <NavLink to={"/about"} className="nav-link">
        About
      </NavLink>
      {/* <NavLink to={"/blog"}>Blog</NavLink> */}
    </nav>
  );
}
