import * as React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Beta from "../images/beta.svg";

export default function Header() {
  return (
    <header>
      <div className="logo">
        <Link to="/">
          Swadesi Vastram <img className="beta-stage-logo" src={Beta} />{" "}
        </Link>
      </div>
      <Navbar />
    </header>
  );
}
