import { motion } from "framer-motion";

const transition = (Component) => {
  return () => {
    return (
      <>
        <motion.div
          className="transition-slide-in"
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 1 }}
          transition={{ duration: 0.3, ease: [0.22, 1, 0.36, 1] }}
        ></motion.div>
        <Component />
        <motion.div
          className="transition-slide-out"
          initial={{ scaleY: 1 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 0 }}
          transition={{ duration: 0.3, ease: [0.22, 1, 0.36, 1] }}
        ></motion.div>
      </>
    );
  };
};
export default transition;
