import * as React from "react";
import transition from "../hoc/transition";
import AboutMain from "../components/About/AboutMain";
import ComingSoon from "../components/ComingSoon";
import Faqs from "../components/About/Faqs";
import Story from "../components/About/Story";

const About = () => {
  return (
    <div>
      <AboutMain />
      <Story />
      <Faqs />
    </div>
  );
};

export default transition(About);
