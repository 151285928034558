import * as React from "react";

export default function Facts() {
  return (
    <div className="facts-wrap">
      <p className="body-text">Facts about Handloom</p>
      {HandloomFacts.map((fact, index) => {
        return <Card key={index} fact={fact} i={index} />;
      })}
    </div>
  );
}

//card

const Card = ({ fact, i }) => {
  return (
    <div className="facts-card-wrap">
      <div
        className="facts-card"
        style={{
          backgroundColor: fact.color,
          top: `calc(-5vh + ${i * 25}px)`,
        }}
      >
        <h2>{fact.title}</h2>
        <div className="facts-card-content">
          <img src={fact.src} />
          <p>{fact.description}</p>
        </div>
      </div>
    </div>
  );
};

// data

const HandloomFacts = [
  {
    title: "A Tool for Empowering Women",
    description:
      "According to The Fourth All India Handloom Census, 2019–20, nearly 72% of handloom weavers in the country are female. Women weavers in India today number over 38 million. Most belong to traditional families of handloom weavers in India, and ever since they were young girls, have been handling most of the pre-weaving work such as preparation of the yarn and the looms, dying and/or tying and dying yarn and fabric, and embellishing garments by hand embroidering them. Even if women are not weaving, they will be part of the allied workforce since it involves the entire family. For handloom, three allied workers are required by the main weaver, and they invariably would be from the same family, involving womenfolk.it is the female artisans that are contributing the most to uplift the handloom traditions that we are so proud of.",
    src: "https://images.unsplash.com/photo-1627705954911-940ac915d557?q=80&w=2047&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    color: "#ECF2FF",
  },
  {
    title: "Indian Institute of Handloom Technology",
    description:
      "In olden days Indian Handloom Industry was not having trained technical personnel and was solely dependent on the expertise and knowledge passed on from generation to generation for its survival and development. It was in this context that the Government of India decided to set up Indian Institutes of Handloom Technology with the prime objective of providing technical personnel trained in new methods and latest development in the field of Handloom to enable the Handloom industry to face the challenges from other sector of the Textile Industry. The first Indian Institute of Handloom Technology was established in 1956 at Varanasi by taking over the Central Weaving Institute, Varanasi. The second Institute was established in 1960 at Salem in Tamil Nadu by taking over the Government Textile Institute at Madras and shifting the same to Salem, one of the important Handloom Weaving Centers in southern India. At present there are six Indian Institutes of Handloom Technology in the Central Sector and four Institutes in State Sector.",
    src: "https://www.iihtsalem.edu.in/wp-content/uploads/2021/05/ban2.jpg",
    color: "#efffdf",
  },
  {
    title: "First National Handloom Day : 7th August 2015.",
    description:
      "The Swadeshi Movement which was launched on 7th August, 1905 had encouraged indigenous industries and in particular handloom weavers.In 2015, the Government of India decided to designate the 7th August every year, as the National Handloom Day. The first National Handloom Day was inaugurated on 7 August 2015 by Prime Minister Narendra Modi in Chennai.On this day, the handloom weaving community is honoured and the contribution of this sector in the socio-economic development of this country is highlighted.",
    src: "https://bl-i.thgim.com/public/migration_catalog/article18227796.ece/alternates/LANDSCAPE_1200/handloom",

    color: "#FFF6DC",
  },
];
