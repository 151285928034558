import * as React from "react";
import { motion } from "framer-motion";

const transition = { duration: 0.4, ease: [0.6, 0.01, -0.05, 0.9] };

const first = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: -1,
    },
  },
};
const last = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};

const letter = {
  initial: {
    y: 400,
  },
  animate: {
    y: 0,
    transition: { duration: 1, ...transition },
  },
};

export default function BlogMain() {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      className="wrapper"
    >
      <motion.div className="heading-text">
        <motion.span className="first" variants={first}>
          <motion.span variants={letter}>B</motion.span>
          <motion.span variants={letter}>L</motion.span>
          <motion.span variants={letter}>O</motion.span>
          <motion.span variants={letter}>G</motion.span>
        </motion.span>
      </motion.div>
    </motion.div>
  );
}
