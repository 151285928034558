import * as React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

export default function ComingSoon() {
  return (
    <div className="coming-soon-banner">
      <span>Coming Soon</span>
    </div>
  );
}
