import NotfoundImage from "../images/NotFound.svg";

function NotFound() {
  return (
    <div className="not-found">
      <h1>Oops! You seem to be lost.</h1>
      <img src={NotfoundImage} />
    </div>
  );
}

export default NotFound;
