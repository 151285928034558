import * as React from "react";
import "./Home.css";
import { motion } from "framer-motion";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const first = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: -1,
    },
  },
};
const last = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};

const letter = {
  initial: {
    y: 400,
  },
  animate: {
    y: 0,
    transition: { duration: 1, ...transition },
  },
};

export default function HeroSection() {
  const [noOfMondays, setNoOfMondays] = React.useState("");
  React.useEffect(() => {
    setNoOfMondays(
      String(countCertainDays([1], new Date(2023, 2, 15), new Date()))
    );
  }, []);

  const countCertainDays = (days, d0, d1) => {
    const ndays = 1 + Math.round((d1 - d0) / (24 * 3600 * 1000));
    const sum = (a, b) => {
      return a + Math.floor((ndays + ((d0?.getDay() + 6 - b) % 7)) / 7);
    };
    return days.reduce(sum, 0);
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      className="wrapper"
    >
      <motion.div className="heading-text">
        <motion.span className="first" variants={first}>
          <motion.span variants={letter}>{noOfMondays.charAt(0)}</motion.span>
          <motion.span variants={letter}>{noOfMondays.charAt(1)}</motion.span>
        </motion.span>
        <motion.span className="last" variants={last}>
          <motion.span variants={letter}>M</motion.span>
          <motion.span variants={letter}>O</motion.span>
          <motion.span variants={letter}>N</motion.span>
          <motion.span variants={letter}>D</motion.span>
          <motion.span variants={letter}>A</motion.span>
          <motion.span variants={letter}>Y</motion.span>
          <motion.span variants={letter}>S</motion.span>
          <motion.span variants={letter}>!</motion.span>
        </motion.span>
      </motion.div>
      <motion.img
        animate={{
          transition: { delay: 0.2, ...transition },
          y: 0,
        }}
        className="hero-img"
        src="https://images.unsplash.com/photo-1640292343595-889db1c8262e?q=80&w=2984&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <motion.p className="body-text">
        I support Indian Handloom, by wearing it on every <span>Monday</span>
      </motion.p>
    </motion.div>
  );
}
