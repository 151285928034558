import * as React from "react";

export default function History() {
  return (
    <div className="history-wrap">
      <p className="body-text">Gandhiji & Handloom</p>
      <img
        className="history-img"
        src="https://www.hindustantimes.com/ht-img/img/2023/09/29/1600x900/Mahatma_Gandhi_1696012443719_1696012443855.jpg"
      />
      <div className="history-sections">
        <div>
          Gandhiji saw two things of great importance in handloom - the fabric
          of the nation & the heritage of a great country like India.
        </div>
        <div>
          The charkha became a symbol of self-sufficiency and freedom, so much
          so that the first Indian flag designed had the Charkha etched in the
          middle which was later replaced by the Ashok Chakra.
        </div>
        <div>
          In the early 20th century, Mahatma Gandhi led the Swadeshi Movement,
          which was a campaign to promote the use of Indian-made goods. This
          movement helped to revive the handloom industry, and it also led to
          the development of new styles of handloom fabrics that were inspired
          by traditional Indian designs.
        </div>
      </div>
    </div>
  );
}

//card
