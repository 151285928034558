import * as React from "react";
import { motion } from "framer-motion";
import "./About.css";
import mixpanel from "mixpanel-browser";
import Tweet from "../../images/about-tweet.png";

const transition = { duration: 0.4, ease: [0.6, 0.01, -0.05, 0.9] };

const first = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: -1,
    },
  },
};
const last = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};

const letter = {
  initial: {
    y: 400,
  },
  animate: {
    y: 0,
    transition: { duration: 1, ...transition },
  },
};

export default function Story() {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      className="wrapper"
    >
      <div className="about-us ">
        <div className="about-section story">
          <h2 className="section-title">When it started?</h2>
          <div className="section-description">
            <div>
              <p>
                On <b>15-Feb-2023</b>, I have taken oath to myself for to wear
                Handloom Dress Every Monday.
              </p>
              <p>
                From then every monday, I started wearing handloom and posting
                in twitter, threads, instagram and whatsapp stories, so it not
                only help me keep my self motivated and disciplined but also
                share to my connections.I should thank my friends who constantly
                take photographs of me wearing handloom, they respect and
                appreciate my decision of this handloom support and help me in
                taking good photographs.
              </p>
            </div>
            <img src={Tweet} />
          </div>
        </div>
        <div className="about-section">
          <h2 className="section-title">Around Bengaluru</h2>
          <div className="section-description">
            <p>
              In July 2023, I googled where do handloom weavers be near
              Bengaluru, to buy a saree for my mother and a shirt cloth for me,
              it showed me a few results, among them Kodiyala is nearby. It is a
              village in Karnataka, 100 km from Bengaluru, and 50km from Mysuru.
            </p>
            <p>
              I always wanted to talk to handloom weavers personally and
              understand how can I be helpful further, so talked to a few
              weavers, but there is a little communication gap. Later, I found a
              Telugu-speaking handloom weaver, who is almost 72 years old, he
              helped me understand what everyone is saying and also said his
              story (from ancestors).
            </p>
            <p>
              He is a 4th generation handloom weaver from his family, who used
              to weave at night along with farming at day at a young age. Slowly
              weaving became a separate occupation itself. He said his mother
              and grandmother, and every woman in their family used to support
              extra income ( especially when farming causes loss) so not only
              software times, from the old days there is a culture of women
              supporting family
            </p>
            <p>
              He is one of the few who didn’t take up power machines due to
              respect for the skill he has! And he says even today hand-powered
              machine work is much more appreciated than power ones. Handloom is
              now mostly from electric-powered machines and threads are mixed
              with polyester for rigidness!
            </p>
            <p>
              He even said every time national handloom comes, the entire media
              comes here and covers it up, but no one promotes it. It is for
              name sake, the government would also not offer enough loans or
              subsidies on machines they built.
            </p>
          </div>
        </div>
        <div className="about-section">
          <h2 className="section-title">Upcoming in Website:</h2>
          <div className="section-description">
            I have taken oath to myself that every monday i will release
            something meaningful and helpful in this website.
            <ul>
              <li>Places to Buy Handloom</li>
              <li>State Wise Handloom</li>
              <li>Social Media list of handloom artisans / weavers</li>
              <li>Current benefits for weavers (by govt.)</li>
              <li>A page for "National Handloom Day"</li>
            </ul>
            <p>and more..</p>
            <p>
              This section will be updated accordingly, whenever new changes are
              pushed. I cannot do this alone, Please provide your suggestions or
              requested features in the form below.
              <button
                className="suggest-form-link"
                onClick={() => {
                  mixpanel.track("suggest-form-fill");
                  window.open("https://forms.gle/LxSgKiScSMALiekj9");
                }}
              >
                Suggest
                <div className="suggest-form-icon">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                    >
                      <path
                        d="M1.05403 31.6175C0.271626 32.3972 0.271626 33.6614 1.05403 34.441C1.83644 35.2207 3.10497 35.2207 3.88737 34.441L1.05403 31.6175ZM35.5599 2.05152C35.5599 0.948871 34.6629 0.0549994 33.5564 0.0549994L15.5251 0.0549994C14.4187 0.0549994 13.5217 0.948871 13.5217 2.05152C13.5217 3.15416 14.4187 4.04804 15.5251 4.04804H31.5529V20.0202C31.5529 21.1228 32.4499 22.0167 33.5564 22.0167C34.6629 22.0167 35.5599 21.1228 35.5599 20.0202L35.5599 2.05152ZM3.88737 34.441L34.9731 3.46327L32.1397 0.639766L1.05403 31.6175L3.88737 34.441Z"
                        fill="#F5A091"
                      />
                    </svg>
                  </span>
                </div>
              </button>
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
