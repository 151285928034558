import * as React from "react";
import { motion } from "framer-motion";
import "./About.css";

const transition = { duration: 0.4, ease: [0.6, 0.01, -0.05, 0.9] };

const first = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: -1,
    },
  },
};
const last = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};

const letter = {
  initial: {
    y: 400,
  },
  animate: {
    y: 0,
    transition: { duration: 1, ...transition },
  },
};

export default function AboutMain() {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      className="wrapper"
    >
      <motion.div className="heading-text">
        <motion.span className="first" variants={first}>
          <motion.span variants={letter}>A</motion.span>
          <motion.span variants={letter}>B</motion.span>
          <motion.span variants={letter}>O</motion.span>
          <motion.span variants={letter}>U</motion.span>
          <motion.span variants={letter}>T</motion.span>
        </motion.span>
        <motion.span className="last" variants={last}>
          <motion.span variants={letter}>U</motion.span>
          <motion.span variants={letter}>S</motion.span>
        </motion.span>
      </motion.div>

      <div className="about-us">
        <p>
          Handloom weaving is a centuries-old tradition that is deeply rooted in
          Indian culture. It is a labor-intensive process that requires great
          skill and patience. When you wear a handloom garment, you are not just
          supporting a traditional craft, you are also supporting the
          livelihoods of thousands of weavers across India. You are also making
          a sustainable choice, as handloom garments are made from natural fiber
          and are often dyed with natural dyes.
        </p>
        <p>
          The handloom industry in India continues to thrive today, and it is
          still one of the largest cottage industries in the country. There are
          millions of handloom weavers in India, and they produce a wide variety
          of textiles, including sarees, shawls, kurtas, and bedcovers.
        </p>
      </div>
    </motion.div>
  );
}
